import PropTypes from "prop-types";
import styled from "styled-components";

export const propTypes = {
  instructorData: PropTypes.shape({}).isRequired,
};

export const Wrapper = styled.div`
  margin: 0 20px 10px 0;
  max-width: 150px;
  min-width: 150px;
  text-align: center;

  ${({ theme }) => theme.mediaQueries.ltmd} {
    max-width: 140px;
    min-width: 140px;
    margin: 10px 10px 10px 0;
  }
`;

export const A = styled.a`
  color: ${({ theme }) => theme.colors.black};

  :hover {
    opacity: 0.8;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary[0]};
  }
`;

export const Img = styled.img`
  width: 100%;
  cursor: pointer;

  :hover {
    opacity: 0.8;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary[0]};
  }
`;

export const Name = styled.h5`
  font-weight: 500;
  font-size: 16px;
  margin: 10px 0 4px;
`;

export const Credits = styled.p`
  font-size: 12px;
`;
