import env from "helpers/env";
import React from "react";
import LazyLoad from "react-lazyload";
import { useDispatch } from "react-redux";
import { userSelectsInstructorAction } from "modules/content";
import { useAccountMode } from "hooks/useAccountMode";
import { Credits, Img, Name, A, Wrapper } from "./styles";

export type InstructorData = {
  slug: string;
  listImagePath: string;
  name: string;
  /**
   * credits
   * Discrepancy in Algolia seeding where old credits were a joined string,
   * and [going forward] new ones have an array of strings :/
   */
  credits?: string | string[];
};

type InstructorCardProps = {
  instructorData: InstructorData;
};

export const InstructorCard = ({ instructorData }: InstructorCardProps) => {
  const { credits, name, listImagePath } = instructorData;
  const dispatch = useDispatch();
  const { accountMode } = useAccountMode();

  const userSelectsInstructor = () => {
    dispatch(
      userSelectsInstructorAction({
        instructorData,
        accountMode,
      })
    );
  };

  return (
    <LazyLoad overflow height={300}>
      <Wrapper>
        <Img
          onClick={userSelectsInstructor}
          src={`${env("PUBLIC_ASSET_PATH")}/instructors/${listImagePath}?w=300`}
        />
        <A onClick={userSelectsInstructor}>
          <Name>{name}</Name>
        </A>
        { credits && (
          <Credits>
            {typeof credits === "string" ? credits : credits.join(", ")}
          </Credits>
        )}
      </Wrapper>
    </LazyLoad>
  );
};
