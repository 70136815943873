import React from "react";
import { connectInfiniteHits } from "react-instantsearch-dom";
import { InstructorCard } from "app/components/InstructorCard";
import Flex from "app/components/Flex";
import { InstructorsInactive } from "app/components/Icon";
import AlgoliaLoader from "app/components/Loader/AlgoliaLoader";
import { Waypoint } from "app/components/Waypoint";
import { AlgoliaInstructorData } from "../types";

interface Props {
  hits: AlgoliaInstructorData[];
  hasMore: boolean;
  refineNext(): void;
}

function Instructors({ hits, hasMore, refineNext }: Props) {
  if (!hits) {
    return null;
  }

  if (hits.length <= 0) {
    return <AlgoliaLoader icon={InstructorsInactive} listType="instructors" />;
  }

  return (
    <Flex flexWrap="wrap">
      {hits.map(hit => (
        <InstructorCard instructorData={hit} key={hit.slug} />
      ))}
      <Waypoint hasMore={hasMore} fetchData={refineNext} />
    </Flex>
  );
}

export const InstructorResults = connectInfiniteHits(Instructors);
