import React, { useEffect, useState } from "react";
import { InstantSearch, Configure } from "react-instantsearch-dom";
import { Location } from "history";
import { useLocation } from "react-router";
import qs from "qs";
import { searchClient } from "app/components/Algolia/constants";
import { AlgoliaIndexes } from "../types";
import { InstructorResults } from "./InstructorResults";

const urlToSearchState = (location: Location) =>
  qs.parse(location.search.slice(1));

export function InstructorSearch() {
  const [searchState, setSearchState] = useState({ query: "" });
  const location = useLocation();

  useEffect(() => {
    setSearchState(urlToSearchState(location));
  }, [location]);

  return (
    <InstantSearch
      indexName={AlgoliaIndexes.instructors}
      searchClient={searchClient}
    >
      <Configure query={searchState.query} />
      <InstructorResults />
    </InstantSearch>
  );
}
